import React, { useEffect, useState } from 'react';
import styles from './styles.scss';
import { Helmet } from 'react-helmet';

interface EmbeddedYouVisitProps {
  ipedscode: string;
  imageURL: string;
  elementId: string;
}

declare global {
  interface Window {
    onYVExperienceOffline: (domEl: HTMLElement) => void;
    vt_initialized?: boolean;
    vt_visit_id?: string;
  }
}

export const EmbeddedYouVisit = ({
  ipedscode,
  imageURL,
  elementId,
}: EmbeddedYouVisitProps) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [showImage, setShowImage] = useState(true);
  window.vt_initialized = undefined;

  useEffect(() => {
    if (window.vt_visit_id) {
      const script = document.getElementById('youvisit-script');
      if (script) {
        script.remove();
        setScriptLoaded(true);
      }
    }
    setScriptLoaded(true);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      const iframePresentOrNot = document.getElementsByClassName(elementId);
      Array.from(iframePresentOrNot).forEach((element) => {
        const iframe = element.getElementsByTagName('iframe');
        const youvisitEmbed = element.getElementsByClassName('youvisit-embed');
        if (iframe.length || youvisitEmbed.length) {
          setShowImage(false);
          clearInterval(interval); // Stop interval when condition is met
        }
      });
    }, 1000);

    // Set timeout to clear the interval after 5 seconds if it hasn’t already been cleared
    const timeout = setTimeout(() => {
      clearInterval(interval);
    }, 15000);

    // Clean up both interval and timeout on component unmount
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [window.vt_initialized]);

  // Because the image is purely decorative and we don't know the content in advance,
  // we should use an empty alt attribute.
  // See: https://www.w3.org/WAI/tutorials/images/decorative/

  if (!scriptLoaded) {
    return <img src={imageURL} alt="" />;
  }

  return (
    <>
      <img src={imageURL} alt="" style={{ display: showImage ? 'block' : 'none' }} />
      <a
        href="https://www.youvisit.com"
        className={styles.virtualtour_embed}
        title="Virtual Reality, Virtual Tour"
        data-autoplay="0"
        data-hide-buttons="7"
        data-remove-prompts="1"
        data-remove-registration="1"
        data-show-vr="1"
        data-link-type="immersive"
        data-iped={ipedscode}
        data-image-width="478"
        data-image-height="250"
        data-platform="powerschool"
        data-cy="youvisit-a"
      >
        Virtual Tour
      </a>
      <Helmet>
        <script
          defer
          src="https://www.youvisit.com/tour/Embed/js2/"
          id="youvisit-script"
        ></script>
      </Helmet>
    </>
  );
};
