import * as React from 'react';
import Chunk from '../../components/Chunk';

const loadMyPlanner = () => import('../../containers/MyPlanner2');

export default class MyPlanner2Route extends React.Component {
  render() {
    return <Chunk load={loadMyPlanner} />;
  }
}
